// biome-ignore lint/suspicious/noExplicitAny: allow any because this is doing a conversion for us
function isDate(v: any): v is Date {
	if (v instanceof Date) {
		const time = v.getTime()
		if (
			// biome-ignore lint/suspicious/noSelfCompare: this is intentional
			time === time // check that it's not NaN (NOTE: IE doesn't support Number.isNan)
		) {
			return true
		}
	}
	return false
}

// biome-ignore lint/suspicious/noExplicitAny: allow any because this is doing a conversion for us
export function newDateFromStr(v: any): Date | null {
	if (typeof v === "string") {
		if (
			v.length === 10 // yyyy-mm-dd
		) {
			v = new Date(`${v}T00:00:00Z`)
		} else if (
			v.length === 19 // yyyy-mm-ddThh:mm:ss
		) {
			v = new Date(`${v}Z`)
		} else {
			v = new Date(v)
		}
	}
	if (isDate(v)) {
		return v
	}
	return null
}

export function isEmailValid(email: string) {
	if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
		return true
	}
	return false

}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const downloadFile = (blob: any, file_name: string) => {
	const doclink = document.createElement("a")
	const url = window.URL.createObjectURL(blob)
	doclink.href = url
	doclink.download = file_name
	doclink.click()
	window.URL.revokeObjectURL(url)
}