import DateFnsUtils from "@date-io/date-fns"
import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Grid from "@material-ui/core/Grid"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import type { Moment } from "moment"
import { type ChangeEvent, Fragment, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {
	getIepList,
	getSettings,
	getSimpleInvoiceRecords,
	post,
	uploadFile
} from "../api"
import Layout from "../components/layout/Layout"
import UploadComp from "../components/upload/UploadComponent"
import { authStore } from "../stores/authStore"
import { toastStore } from "../stores/toastStore"

const moment = require("moment")

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
		paper: {
			position: "absolute",
			width: 500,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

async function validateBulkRecords(
	invRecIds: Array<number>,
	daysOffset: number,
	doneCallback: () => void,
	errorCallback: () => void,
) {
	if (invRecIds) {
		await post(
			`invoice/records/validate/${daysOffset}/0`,
			{ recIDs: invRecIds },
			false,
		)
			.then(doneCallback)
			.catch(() => {
				errorCallback()
				return
			})
	}
}

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

interface Props {
	style: any
	match: any
}

export default function (props: Props) {
	const classes = useStyles()
	const [modalStyle] = useState(getModalStyle)
	const [importFileType, setImportFileType] = useState<string | undefined>()
	const [isManager, setIsManager] = useState<boolean>(true)
	const [fileValue, setFileValue] = useState<any>()
	const inputLabel = useRef<HTMLLabelElement>(null)
	const [iepSelected, setIepSelected] = useState(-1)
	const [invoiceNum, setInvoiceNum] = useState<string>()
	const [iepList, setIepList] = useState<any | undefined>()
	const [daysOffset, setDaysOffset] = useState<number | undefined>(0)
	const [open, setOpen] = useState(false)
	const [textWhileWait, setTextWhileWait] = useState("")
	const [settings, setSettings] = useState<any | undefined>()
	const [labelWidth, setLabelWidth] = useState(0)

	const auth = authStore.use()
	const me = auth.me

	const [selectedDate, setSelectedDate] = useState<Moment | null>(moment())
	const history = useHistory()
	useEffect(() => {
		if (!props.match.path.includes("manager")) {
			setImportFileType("iep")
			setIsManager(false)
			loadPageInfo()
		} else {
			setImportFileType("cr")
			setIsManager(true)
		}
		if (inputLabel && inputLabel.current) {
			setLabelWidth(inputLabel.current!.offsetWidth)
		}
	}, [])

	useEffect(() => {
		if (settings) {
			settings.forEach((setting) => {
				if (setting) {
					if (setting.name == "DaysOffsetForValidate") {
						setDaysOffset(Number.parseInt(setting.value))
					}
				}
			})
		}
	}, [settings])

	const handleDateChange = (date: Moment | null) => {
		setSelectedDate(date)
	}

	const loadPageInfo = () => {
		if (!iepList) {
			getIepList(setIepList)
		}
		if (!settings) {
			getSettings(setSettings)
		}
	}

	const handleInvoiceNumChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInvoiceNum((event.target as HTMLInputElement).value)
	}

	const handlefileTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
		setImportFileType((event.target as HTMLInputElement).value)
	}

	const handleIEPDDChange = (event: ChangeEvent<{ value: unknown }>) => {
		setIepSelected(event.target.value as number)
	}

	const handleDaysChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+/)
		setDaysOffset(event.target.value)
	}

	const handleProcessClick = () => {
		if (
			fileValue &&
			((importFileType === "iep" && iepSelected && invoiceNum) ||
				importFileType === "cr" ||
				importFileType === "pool")
		) {
			const formData = new FormData()
			const choosenDt = selectedDate ? moment.utc(selectedDate) : moment()
			var docDate = choosenDt.format("L")
			const additdata = '{"username":"' +
				me?.username +
				'", "docDT":"' +
				docDate +
				'", "iepID":' +
				iepSelected +
				', "invoiceNum":"' +
				invoiceNum +
				'"}'
			formData.append("data", additdata)
			formData.append("file", fileValue)
			setOpen(true)
			setTextWhileWait("...Uploading File")
			uploadFile(
				formData,
				importFileType === "iep",
				importFileType === "cr",
				importFileType === "pool"
			)
				.then((data) => {
					if (importFileType === "iep") {
						setTextWhileWait("...Validating Invoice Records")
						getSimpleInvoiceRecords(data.invoice_id, (data2: Array<any>) => {
							if (data2) {
								setTextWhileWait("...Validating Invoice Records: " + data2.length)
								validateBulkRecords(
									data2,
									daysOffset || 2,
									() => {
										history.push("/invoiceworkflow/" + data.invoice_id)
									},
									() => {
										// show this toast for a long time as the user is probably not looking at this tab
										// 600000ms = 10 minutes 
										toastStore.showToast("error", "File was uploaded but validation Failed to complete. Navigate to the invoice to retry", 600000)
										setOpen(false)
									},
								)
							}
						})
					} else if (
						importFileType == "cr" ||
						importFileType == "pool" ||
						importFileType == "terminal" ||
						importFileType == "ssl"
					) {
						setTextWhileWait("DONE! ")
						history.push("/dashboard")
					}
				})
				.catch((err) => {
					setOpen(false)
					toastStore.showToast("error", err.message)
				})
		}
	}

	if (props.match.path.includes("manager") && !isManager) {
		setImportFileType("cr")
		setIsManager(true)
	}
	if (!props.match.path.includes("manager") && isManager) {
		setImportFileType("iep")
		setIsManager(false)
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={open}>
					<div style={modalStyle} className={classes.paper}>
						{textWhileWait}
					</div>
				</Modal>
				<Box style={{ padding: 30 }}>
					<Grid
						container
						style={{
							width: "auto",
							margin: "0% 20%",
						}}>
						<Grid item sm={12} xs={12} style={{ padding: 30 }}>
							<Box justifyContent="center" display="flex">
								UPLOAD FILES
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Grid container style={{ padding: 30 }}>
								<Grid item sm={4}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Select file type</FormLabel>
										<RadioGroup
											aria-label="file"
											name="file2"
											value={importFileType}
											onChange={handlefileTypeChange}>
											{isManager ? (
												<Fragment>
													<FormControlLabel
														value="cr"
														control={<Radio style={{ color: "#707070" }} />}
														label="Customer Rules"
														labelPlacement="end"
													/>
													<FormControlLabel
														value="pool"
														control={<Radio style={{ color: "#707070" }} />}
														label="Pools"
														labelPlacement="end"
													/>
												</Fragment>
											) : (
												<Fragment>
													<FormControlLabel
														value="iep"
														control={<Radio style={{ color: "#707070" }} />}
														label="Invoice"
														labelPlacement="end"
													/>
												</Fragment>
											)}
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item sm={8}>
									<UploadComp setFile={setFileValue} />
									{!isManager && (
										<Fragment>
											<TextField
												style={{ marginTop: 10, width: "100%" }}
												label="Days for Offset"
												variant="outlined"
												value={daysOffset}
												onChange={handleDaysChange}
											/>
										</Fragment>
									)}
								</Grid>
							</Grid>
						</Grid>
						{!isManager && importFileType === "iep" && (
							<Grid item sm={12}>
								<Grid container>
									<Grid item sm={6} style={{ padding: 15 }}>
										<FormControl variant="outlined" style={{ width: "100%" }}>
											<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
												Select IEP
											</InputLabel>
											<Select
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												onChange={handleIEPDDChange}
												labelWidth={labelWidth}
												value={iepSelected}>
												{iepList &&
													iepList.map((i: any) => {
														return <MenuItem value={i.id_}>{i.abbrv}</MenuItem>
													})}
											</Select>
										</FormControl>
									</Grid>
									<Grid item sm={6} style={{ padding: 15 }}>
										<TextField
											id="outlined-basic"
											label="Invoice #"
											variant="outlined"
											style={{ width: "100%" }}
											onChange={handleInvoiceNumChange}
										/>
									</Grid>
									<Grid item sm={6}></Grid>
									<Grid item sm={6} style={{ padding: 15 }}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline"
												label="Date invoice received"
												value={selectedDate}
												style={{ width: "100%" }}
												onChange={handleDateChange}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>
										</MuiPickersUtilsProvider>
										<Box>Today's Date: {moment().format("L")}</Box>
									</Grid>
								</Grid>
							</Grid>
						)}
						{(importFileType === "" || importFileType === undefined) && <Grid item sm={12}></Grid>}

						<Grid item sm={12}>
							<Box justifyContent="center" display="flex" style={{ margin: 30 }}>
								<Button
									variant="contained"
									color="primary"
									size="medium"
									disabled={fileValue === undefined || importFileType === undefined ? true : false}
									onClick={handleProcessClick}>
									Process
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
