import DateFnsUtils from "@date-io/date-fns"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import type { Moment } from "moment"
import React from "react"
import {
	SearchTrinium,
	getInvoiceRecord,
	getInvoiceRecordPossibleMatches
} from "../api"
import type { IepRecord } from "../types/IepRecord"
import type { ManifestRef, PossibleMatches } from "../types/RecordSearch"
import TriniumSearch from "./CustomTable/triniumResultsTable/TriniumResults"
import WaitPopup from "./WaitPopup"

const moment = require("moment")

interface Props {
	isClosed: Function
	iepID?: number
	invoiceRecordID?: number
	assignTriniumManifest?: Function
	unassignTriniumManifest?: Function
	disableAssignment?: boolean
	invoiceRecordData?: any | undefined
}

export default function TriniumRecordSearch(props: Props) {
	const [manifest, setManifest] = React.useState<any | undefined>("")
	const [chassis, setChassis] = React.useState<any | undefined>("")
	const [container, setContainer] = React.useState<any | undefined>("")
	const [ssl, setSSL] = React.useState<any | undefined>("")
	const [pickupDT, setPickupDT] = React.useState<Moment | undefined>(null)
	const [deliveryDT, setDeliveryDT] = React.useState<Moment | undefined>(null)
	const [iepID, setIepID] = React.useState<number | undefined>(null)
	const [triniumData, setTriniumData] = React.useState<any | undefined>()
	const [open, setOpen] = React.useState<boolean>(true)
	const [invoiceRecord, setInvoiceRecord] = React.useState<IepRecord | undefined>()
	const [manifestSelected, setManifestSelected] = React.useState<any | undefined>()
	const [manifestUnselected, setManifestUnselected] = React.useState<any | undefined>()
	const [possibleMatches, setPossibleMatches] = React.useState<PossibleMatches | undefined>()
	const [manifestRefData, setManifestRefData] = React.useState<ManifestRef[] | undefined>()

	let invoiceRecordID: number | undefined = undefined
	const invoiceRecordData = props.invoiceRecordData
	if (props.invoiceRecordID) {
		invoiceRecordID = props.invoiceRecordID
	}

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (triniumData) {
			setOpen(false)
		}
	}, [triniumData])

	React.useEffect(() => {
		if (invoiceRecord) {
			if (invoiceRecord.manifest_num) {
				setManifest(invoiceRecord.manifest_num)
			}
			if (invoiceRecord.chassis) {
				setChassis(invoiceRecord.chassis)
			}
			if (invoiceRecord.container1) {
				setContainer(invoiceRecord.container1)
			}
			if (invoiceRecord.invoice_ssl) {
				setSSL(invoiceRecord.invoice_ssl)
			}
			if (invoiceRecord.dt_out) {
				const tempPickupDT: Moment = moment.utc(invoiceRecord.dt_out)
				setPickupDT(tempPickupDT)
			}
			if (invoiceRecord.dt_in) {
				const tempDeliveryDT: Moment = moment.utc(invoiceRecord.dt_in)
				setDeliveryDT(tempDeliveryDT)
			}
			if (invoiceRecord.iep_id) {
				setIepID(invoiceRecord.iep_id)
			}
			setOpen(false)
		}
	}, [invoiceRecord])

	React.useEffect(() => {
		if (manifestSelected && props.assignTriniumManifest) {
			setManifestUnselected(undefined)
			props.assignTriniumManifest(manifestSelected)
		}
	}, [manifestSelected, props.assignTriniumManifest])

	React.useEffect(() => {
		if (manifestUnselected && props.unassignTriniumManifest) {
			setManifestSelected(undefined)
			props.unassignTriniumManifest(manifestUnselected)
		}
	}, [manifestUnselected, props.unassignTriniumManifest])

	React.useEffect(() => {
		if (possibleMatches) {
			if (possibleMatches.trin) {
				let tempTriniumData = []
				tempTriniumData = dateFormatting(possibleMatches.trin)
				setTriniumData(tempTriniumData)
			}
			if (possibleMatches.ref) {
				setManifestRefData(possibleMatches.ref)
			}
		}
	}, [possibleMatches])

	function loadPageInfo() {
		let doesNotHaveAnyThing = true
		if (invoiceRecordID) {
			getInvoiceRecord(invoiceRecordID, setInvoiceRecord)
			getInvoiceRecordPossibleMatches(invoiceRecordID)
				.then((data) => {
					setPossibleMatches(data)
				})
				.catch(console.log)
			doesNotHaveAnyThing = false
		}

		if (doesNotHaveAnyThing) setOpen(false)
	}

	const dateFormatting = (data) => {
		const tempTriniumData = []
		data.forEach((item) => {
			if (item.delivery_date) {
				item.delivery_date = moment.utc(item.delivery_date)
			}
			if (item.pickup_date) {
				item.pickup_date = moment.utc(item.pickup_date)
			}
			tempTriniumData.push(item)
		})
		tempTriniumData.sort((a, b) => {
			return b.pickup_date - a.pickup_date
		})
		return tempTriniumData
	}

	const handleClosed = () => () => {
		props.isClosed(true)
	}

	const handleTextChange = (event: any, setFunction: Function) => {
		setFunction(event.target.value)
	}

	const handlePickupDateChange = (date: Moment | null) => {
		setPickupDT(moment.utc(date))
	}

	const handleDeliveryDateChange = (date: Moment | null) => {
		setDeliveryDT(moment.utc(date))
	}

	const handleSearchClick = () => {
		setOpen(true)
		const searchObj: any = {}
		if (manifest) {
			searchObj.manifest = manifest
		}
		if (chassis) {
			searchObj.chassis = chassis
		}
		if (container) {
			searchObj.container = container
		}
		if (ssl) {
			searchObj.ssl = ssl
		}
		if (pickupDT) {
			searchObj.pickupDT = pickupDT.format("L")
		}
		if (deliveryDT) {
			searchObj.deliveryDT = deliveryDT.format("L")
		}
		if (iepID) {
			searchObj.iepID = iepID
		}

		SearchTrinium(searchObj, (data) => {
			let tempTriniumData = []
			tempTriniumData = dateFormatting(data)
			setTriniumData(tempTriniumData)
			setOpen(false)
		})
	}

	const displayManifest = (manifest) => {
		return (
			<Grid container>
				<Grid item sm={2}>
					{manifest.manifest_num ? manifest.manifest_num.toUpperCase() : ""}
				</Grid>
				<Grid item sm={2}>
					{manifest.chassis ? manifest.chassis.toUpperCase() : ""}
				</Grid>
				<Grid item sm={2}>
					{manifest.container1 ? manifest.container1.toUpperCase() : ""}
				</Grid>
				<Grid item sm={2}>
					{manifest.invoice_ssl ? manifest.invoice_ssl.toUpperCase() : ""}
				</Grid>
				<Grid item sm={2}>
					{manifest.dt_out ? moment.utc(manifest.dt_out).format("L") : ""}
				</Grid>
				<Grid item sm={2}>
					{manifest.dt_in ? moment.utc(manifest.dt_in).format("L") : ""}
				</Grid>
			</Grid>
		)
	}

	// const displayManifests = (manifest) => {
	//   return (
	//     <Grid container>
	//       <Grid item sm={2}>
	//         {manifest.full_manifest ? manifest.full_manifest.toUpperCase() : ""}
	//       </Grid>
	//       <Grid item sm={2}>
	//         {manifest.chassis ? manifest.chassis.toUpperCase() : ""}
	//       </Grid>
	//       <Grid item sm={2}>
	//         {manifest.container ? manifest.container.toUpperCase() : ""}
	//       </Grid>
	//       <Grid item sm={2}>
	//         {manifest.ssl ? manifest.ssl.toUpperCase() : ""}
	//       </Grid>
	//       <Grid item sm={2}>
	//         {manifest.pickup_date ? moment.utc(manifest.pickup_date).format("L") : ""}
	//       </Grid>
	//       <Grid item sm={2}>
	//         {manifest.delivery_date ? moment.utc(manifest.delivery_date).format("L") : ""}
	//       </Grid>
	//     </Grid>
	//   );
	// };

	return (
		<Box style={{ margin: 10, minWidth: 600 }}>
			<WaitPopup open={open}>{"...Loading Info."}</WaitPopup>
			<Grid container>
				<Grid item>
					<Box>
						<h3>RECORD SEARCH PARAMETERS</h3>
					</Box>
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item sm={2} style={{ padding: 5 }}>
							<TextField
								id="outlined-basic"
								label="Manfest #"
								variant="outlined"
								value={manifest}
								onChange={(event: any) => {
									handleTextChange(event, setManifest)
								}}
							/>
						</Grid>
						<Grid item sm={2} style={{ padding: 5 }}>
							<TextField
								id="outlined-basic"
								label="Chassis #"
								variant="outlined"
								value={chassis}
								onChange={(event: any) => {
									handleTextChange(event, setChassis)
								}}
							/>
						</Grid>
						<Grid item sm={2} style={{ padding: 5 }}>
							<TextField
								id="outlined-basic"
								label="Container #"
								variant="outlined"
								value={container}
								onChange={(event: any) => {
									handleTextChange(event, setContainer)
								}}
							/>
						</Grid>
						<Grid item sm={2} style={{ padding: 5 }}>
							<TextField
								id="outlined-basic"
								label="SSL"
								variant="outlined"
								value={ssl}
								onChange={(event: any) => {
									handleTextChange(event, setSSL)
								}}
							/>
						</Grid>
						<Grid item sm={2} style={{ padding: 5 }}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									error={false}
									id="date-picker-inline"
									label="Pickup Date"
									value={pickupDT}
									style={{ width: "100%" }}
									onChange={handlePickupDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item sm={2} style={{ padding: 5 }}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									error={false}
									id="date-picker-inline"
									label="Delivery Date"
									value={deliveryDT}
									style={{ width: "100%" }}
									onChange={handleDeliveryDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={12}>
					<Box style={{ float: "right" }}>
						<Button variant="contained" color="primary" onClick={handleSearchClick}>
							Search
						</Button>
					</Box>
				</Grid>
				{props.invoiceRecordID ? (
					<Grid item sm={12}>
						<Box>
							<Grid container>
								<Grid item sm={12}>
									<h4 style={{ margin: 0 }}>CURRENT REC</h4>
								</Grid>
								<Grid item sm={12}>
									{invoiceRecord ? (
										<React.Fragment>
											<Grid container>
												<Grid item sm={2}>
													<b>Manifest</b>
												</Grid>
												<Grid item sm={2}>
													<b>Chassis</b>
												</Grid>
												<Grid item sm={2}>
													<b>Container</b>
												</Grid>
												<Grid item sm={2}>
													<b>ssl</b>
												</Grid>
												<Grid item sm={2}>
													<b>Pick up Date</b>
												</Grid>
												<Grid item sm={2}>
													<b>Delivery Date</b>
												</Grid>
											</Grid>
											{displayManifest(invoiceRecordData)}
										</React.Fragment>
									) : (
										""
									)}
								</Grid>
							</Grid>
						</Box>
					</Grid>
				) : null}
				<Grid item sm={12} id="gd_resultTable">
					<TriniumSearch
						triniumResults={triniumData}
						manifestRefData={manifestRefData ? manifestRefData : null}
						assignTriniumManifest={props.assignTriniumManifest ? setManifestSelected : null}
						unassignTriniumManifest={props.unassignTriniumManifest ? setManifestUnselected : null}
						reloadDataFunction={loadPageInfo}
						disableAssignment={props.disableAssignment}
					/>
				</Grid>
				<Grid item sm={12}>
					<Box style={{ float: "right", marginTop: 10 }}>
						<Button onClick={handleClosed()} variant="outlined" color="primary">
							Close
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}
