import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { type RouteProps, useHistory, useParams } from "react-router-dom"
import {
	createDisputeHistory,
	getInvoice,
	getSettings,
	getSimpleInvoiceRecords,
	getStatusListByCategory,
	post,
	unlockInvoice,
} from "../api"
import { getDisputeReasons } from "../api/disputeReasonAPI"
import { getInvoiceReconciliationsByID } from "../api/invoiceReconciliationsAPI"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import InvoiceRecordApprovalCriteria from "../components/InvoiceRecordApprovalCriteria"
import InvoiceRecordDisputeDetails from "../components/InvoiceRecordDisputeDetails"
import { TabPanel } from "../components/TabPanel"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { a11yTabNames } from "../lib/a11y"
import { formatDateNoTimezoneMMDDYYYY } from "../lib/formatters"
import { descendingComparator } from "../lib/sort"
import { authStore } from "../stores/authStore"
import type { InvoiceDTO } from "../types/Invoice"
import type { DisputeReasonDTO } from "../types/tables/DisputeReason"
import type { IEPInvoicePlus, getInvoiceReconciliationsDTO } from "../types/tables/IEPRecord"
import type { RecordStatusDTO } from "../types/tables/RecordStatus"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		modal: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		button: {
			margin: theme.spacing(1),
		},
	}),
)

async function validateBulkRecords(invRecIds, daysOffset, callBack, doneCallback) {
	if (invRecIds) {
		let res = await post(
			`invoice/records/validate/${daysOffset}/1`,
			{ recIDs: invRecIds },
			false,
		)
		while (res?.recIDs && res.recIDs.length > 0) {
			callBack(`...Validating Invoice Records: ${res.recIDs.length}`)
			res = await post(
				`invoice/records/validate/${daysOffset}/1`,
				{ recIDs: res.recIDs },
				false,
			)
		}
		doneCallback()
	}
}

export default function InvoiceWorkflowPage(props: RouteProps) {
	const classes = useStyles()
	const [selectedTab, setSelectedTab] = React.useState(0)
	const [invoiceReconciliations, setInvoiceReconciliations] = React.useState<getInvoiceReconciliationsDTO[]>()
	const [pendingFilteredRecs, setPendingFilteredRecs] = React.useState<IEPInvoicePlus[]>([])
	const [approvedFilteredRecs, setApprovedFilteredRecs] = React.useState<IEPInvoicePlus[]>([])
	const [disputedRecs, setDisputedRecs] = React.useState<IEPInvoicePlus[]>([])
	const [disputedFilteredRecs, setDisputedFilteredRecs] = React.useState<IEPInvoicePlus[]>([])
	const [creditedRecs, setCreditedRecs] = React.useState<IEPInvoicePlus[]>([])
	const [terminalRejectedRecs, setTerminalRejectedRecs] = React.useState<IEPInvoicePlus[]>([])
	const [invoice, setInvoice] = React.useState<InvoiceDTO>()
	const [statusList, setStatusList] = React.useState<RecordStatusDTO[]>()
	const [disputeReasons, setDisputeReasons] = React.useState<DisputeReasonDTO[]>()
	const [recordFound, setRecordFound] = React.useState<boolean>(false)
	const [searchRecordId, setSearchRecordId] = React.useState<string | undefined>(undefined)
	const { id: invoiceID } = useParams<{ id: string }>()
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [textWhileWait, setTextWhileWait] = React.useState("...Loading Info")

	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()
	const tabIndexes = {
		REVIEW: 0,
		APPROVED: 1,
		DISPUTED: 2,
		CREDITED: 3,
		TERMINAL_REJECTED: 4,
	}

	React.useEffect(() => {
		getAndSetSearchRecordId()
		loadPageInfo()
	}, [])


	React.useEffect(() => {

		if (invoiceReconciliations) {
			filterInvoiceRecords(invoiceReconciliations)
			setOpenWait(false)
		}
	}, [invoiceReconciliations])

	React.useEffect(() => {
		const checkRecordsForRecId = (recs: IEPInvoicePlus[]) => {
			return recs.some((rec) => rec.id_.toString() === searchRecordId)
		}

		if (checkRecordsForRecId(pendingFilteredRecs)) {
			handleRecordIdFound(tabIndexes.REVIEW)
		} else if (checkRecordsForRecId(approvedFilteredRecs)) {
			handleRecordIdFound(tabIndexes.APPROVED)
		} else if (checkRecordsForRecId(disputedFilteredRecs)) {
			handleRecordIdFound(tabIndexes.DISPUTED)
		} else if (checkRecordsForRecId(creditedRecs)) {
			handleRecordIdFound(tabIndexes.CREDITED)
		} else if (checkRecordsForRecId(terminalRejectedRecs)) {
			handleRecordIdFound(tabIndexes.TERMINAL_REJECTED)
		}
	}, [searchRecordId, pendingFilteredRecs, approvedFilteredRecs, disputedFilteredRecs, creditedRecs, terminalRejectedRecs])



	React.useEffect(() => {
		if (disputedRecs) {
			for (const rec of disputedRecs) {
				if (rec.matches && rec.matches.length > 0) {
					rec.booking_bol = rec.matches[0].booking_bol
					rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
					rec.customer_name = rec.matches[0].customer_name

					if (rec.matches.length > 1) {
						rec.manifest = `'${rec.manifest_num}`
					} else {
						rec.manifest = rec.manifest_num
					}
				}
			}
			setDisputedFilteredRecs(disputedRecs)
		}
	}, [disputedRecs])

	const handleRecordIdFound = (foundTab: number) => {
		setRecordFound(true)
		setSelectedTab(foundTab)
	}

	const getAndSetSearchRecordId = () => {
		const query = new URLSearchParams(props.location.search)
		const recordId = query.get("record")?.toString()
		if (recordId) {
			setSearchRecordId(recordId)
		}
	}


	const clearSearchedRecord = () => {
		history.push(props.location.pathname)
		setRecordFound(false)
		setSearchRecordId(undefined)
	}

	const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue)
		clearSearchedRecord()
		setOpenWait(true)
		loadPageInfo()
	}


	const handleReValidate = () => {
		setOpenWait(true)
		setTextWhileWait("...Validating Invoice Records")
		getSimpleInvoiceRecords(invoiceID, (data2) => {
			if (data2) {
				setTextWhileWait(`...Validating Invoice Records: ${data2.length}`)
				let daysOffset = undefined

				getSettings((data) => {
					if (data) {
						for (const item of data) {
							if (item.name === "DaysOffsetForValidate") {
								daysOffset = item.value
							}
						}
						validateBulkRecords(data2, daysOffset, setTextWhileWait, () => {
							setTextWhileWait("...Loading Info")
							loadPageInfo()
						})
					}
				})
			}
		})
	}


	const handleUnlock = () => {
		unlockInvoice(invoiceID, me.username, (data) => {
			if (data.unlock === true) {
				loadPageInfo()
			} else {
				setOpenWait(true)
				setTextWhileWait("Can NOT unlock invoice. Invoice has an AP record.")
			}
		})
	}

	const handleReviewClick = () => {
		createDisputeHistory(invoiceID, me.username, () => {
			history.push(`/invoicereviewworkflow/${invoice ? invoice.id_ : -1}`)
		})
	}


	const popoverInvoiceRecordDisputeDetails = (id: number, disable: boolean) => {
		return (
			<InvoiceRecordDisputeDetails
				invoiceRecordID={id}
				disableControls={disable ? disable : false}
			/>
		)
	}


	const popoverInvoiceRecordApprovalDetails = (id: number, disableControls: boolean) => {
		return <InvoiceRecordApprovalCriteria invoiceRecordID={id} disableControls={disableControls} />
	}


	const sortAndSetDisputeReasons = (disputeReasons: DisputeReasonDTO[]) => {
		const sortedDisputeReasons = disputeReasons.sort((a, b) => {
			return -descendingComparator(a, b, "reason")
		})
		setDisputeReasons(sortedDisputeReasons)
	}


	const loadPageInfo = () => {

		if (!statusList) {
			getStatusListByCategory("invoice", setStatusList)
		}

		if (!disputeReasons) {
			getDisputeReasons(sortAndSetDisputeReasons)
		}

		getInvoice(+invoiceID, setInvoice)

		getInvoiceReconciliationsByID(invoiceID).then((data) => {
			if (data) {
				setInvoiceReconciliations(data)
			}
			else {
				setOpenWait(false)
			}
		})
	}


	const filterInvoiceRecords = (invoiceRecords: getInvoiceReconciliationsDTO[]) => {
		// Invoice Reconciliations Approved
		const invoiceReconciliationsApproved = (invoiceRecords).filter(
			(item) => item.record_status_name === "APPROVED",
		)
		const approvedRecsPluss = addPropertiesToArray(invoiceReconciliationsApproved)
		setApprovedFilteredRecs(approvedRecsPluss)

		// Invoice Reconciliations Disputed and Credited
		const invoiceReconciliationsDisputed = (invoiceRecords).filter(
			(item) => item.record_status_name === "DISPUTED",
		)
		const dispCreditRecsPluss = addPropertiesToArray(invoiceReconciliationsDisputed)

		const tempCredited = []
		const tempDisputed = []

		for (const rec of dispCreditRecsPluss) {
			if (rec.dispute_transactions && rec.dispute_transactions.length > 0) {
				const disputeForRecord = rec.dispute_transactions.find(
					(d) => d.invoice_record_id === rec.id_ && (d.credit_amt ?? 0) > 0,
				)
				if (disputeForRecord) {
					tempCredited.push(rec)
				} else {
					tempDisputed.push(rec)
				}
			}
		}

		setDisputedRecs(tempDisputed)
		setCreditedRecs(tempCredited)

		// Pending Recs
		const invoiceReconciliationsPending = (invoiceRecords).filter(
			(item) => item.record_status_name === "REVIEW",
		)
		const pendingRecsPluss = addPropertiesToArray(invoiceReconciliationsPending)
		setPendingFilteredRecs(pendingRecsPluss)

		// Terminal Rejected Recs
		const invoiceReconciliationsRejected = (invoiceRecords).filter(
			(item) => item.record_status_name === "DISAPPROVED",
		)
		setTerminalRejectedRecs(addPropertiesToArray(invoiceReconciliationsRejected as IEPInvoicePlus[]))
	}


	const addPropertiesToArray = (recs: IEPInvoicePlus[]) => {
		for (const rec of recs) {
			if (rec.matches?.length) {
				rec.booking_bol = rec.matches[0].booking_bol
				rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
				rec.customer_name = rec.matches[0].customer_name

				if (rec.matches.length > 1) {
					rec.manifest = `'${rec.manifest_num}`
				} else {
					rec.manifest = rec.manifest_num
				}
			}
		}

		return recs
	}


	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup
					open={openWait}
					closeOnRequest={() => {
						setOpenWait(false)
					}}>
					{textWhileWait}
				</WaitPopup>
				{/* This popup should only show up if a user manual changes the record query in the URL */}
				<WaitPopup
					open={searchRecordId && !recordFound && !openWait}
					closeOnRequest={() => {
						clearSearchedRecord()
					}}>
					Record not found in this invoice
				</WaitPopup>
				{invoice ? (
					Object.keys(invoice).length ? (
						<Box style={{ padding: 30 }}>
							<Grid container style={{ overflow: "hidden", width: "100%" }}>
								<Grid item sm={12}>
									<Grid container>
										<Grid item sm={9}>
											<Box>
												<h3>
													IEP INVOICE - #{invoice ? invoice.invoice_num : ""} -{" "}
													{invoice
														? formatDateNoTimezoneMMDDYYYY(invoice.documented_dt)
														: null}{" "}
													-{invoice ? invoice.iep_name : ""}
												</h3>
											</Box>
										</Grid>
										<Grid item sm={3} style={{ whiteSpace: "nowrap" }}>
											<div style={{ float: "right" }}>
												<Button
													className={classes.button}
													size="small"
													disabled={
														invoice ? !!(invoice.is_completed || invoice.ignore_update_dt) : false
													}
													variant="outlined"
													color="primary"
													onClick={handleReValidate}>
													ReValidate
												</Button>
												<Button
													className={classes.button}
													size="small"
													disabled={
														invoice?.is_completed ? !!invoice.ignore_update_dt : true
													}
													variant="outlined"
													color="primary"
													onClick={handleUnlock}>
													CLERICAL CHANGE
												</Button>
												{!pendingFilteredRecs.length ? (
													<Button
														className={classes.button}
														size="small"
														variant="contained"
														color="primary"
														onClick={handleReviewClick}>
														Review
													</Button>
												) : (
													<Button
														disabled
														className={classes.button}
														size="small"
														variant="contained"
														color="primary">
														Review
													</Button>
												)}
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Grid item sm={12}>
									<Tabs value={selectedTab} onChange={handleChange}>
										<Tab
											label={`Needs Review (${pendingFilteredRecs.length})`}
											{...a11yTabNames(0)}
										/>
										<Tab label={`Approved (${approvedFilteredRecs.length})`} {...a11yTabNames(1)} />
										<Tab label={`To Dispute (${disputedRecs.length})`} {...a11yTabNames(2)} />
										<Tab label={`Credited (${creditedRecs.length})`} {...a11yTabNames(3)} />
										<Tab
											label={`Terminal Rejected (${terminalRejectedRecs.length})`}
											{...a11yTabNames(4)}
										/>
									</Tabs>
									<Box>
										<TabPanel value={selectedTab} index={tabIndexes.REVIEW}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={false}
												viewReview={true}
												viewPopOver={true}
												invoiceData={pendingFilteredRecs ? pendingFilteredRecs : []}
												statusData={statusList ? statusList : []}
												disputeReasonData={disputeReasons ? disputeReasons : []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={!!invoice?.ignore_update_dt}
												disableControls={false}
												disableDropdown={false}
												searchRecordId={searchRecordId}
											/>
										</TabPanel>
										<TabPanel value={selectedTab} index={tabIndexes.APPROVED}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={false}
												viewReview={true}
												viewPopOver={true}
												invoiceData={approvedFilteredRecs ? approvedFilteredRecs : []}
												statusData={statusList ? statusList : []}
												disputeReasonData={disputeReasons ? disputeReasons : []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={!!invoice?.ignore_update_dt}
												disableControls={false}
												disableDropdown={false}
												searchRecordId={searchRecordId}
											/>
										</TabPanel>
										<TabPanel value={selectedTab} index={tabIndexes.DISPUTED}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={disputedFilteredRecs || []}
												statusData={statusList || []}
												disputeReasonData={disputeReasons || []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={!!invoice?.ignore_update_dt}
												disableControls={false}
												disableDropdown={false}
												searchRecordId={searchRecordId}
											/>
										</TabPanel>
										<TabPanel value={selectedTab} index={tabIndexes.CREDITED}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={creditedRecs || []}
												statusData={statusList || []}
												disputeReasonData={disputeReasons || []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={!!invoice?.ignore_update_dt}
												disableControls={false}
												disableDropdown={false}
												searchRecordId={searchRecordId}
											/>
										</TabPanel>
										<TabPanel value={selectedTab} index={tabIndexes.TERMINAL_REJECTED}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={terminalRejectedRecs || []}
												statusData={statusList || []}
												disputeReasonData={disputeReasons || []}
												PopoverControl={popoverInvoiceRecordDisputeDetails}
												maxHeight={600}
												disableControls={false}
												disableDropdown={false}
												searchRecordId={searchRecordId}
											/>
										</TabPanel>
									</Box>
								</Grid>
							</Grid>
						</Box>
					) : (
						"Invoice not found"
					)
				) : null}
			</Paper>
		</Layout>
	)
}
