import Badge from "@material-ui/core/Badge"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import ListItemText from "@material-ui/core/ListItemText"
import Menu, { type MenuProps } from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import AccountCircle from "@material-ui/icons/AccountCircle"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import NotificationsIcon from "@material-ui/icons/Notifications"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import { getTerminalNotifications, markNotificationRead } from "../../api"
import { authStore } from "../../stores/authStore"

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props: MenuProps) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
))

const StyledMenuItem = withStyles((theme) => ({
	root: {
		"&:focus": {
			backgroundColor: "white",
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: "rgba(110, 174, 222, 1)",
			},
		},
	},
}))(MenuItem)

export default function ProfileHeader() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null)
	const [notifications, setNotifications] = React.useState<any | undefined>()
	const auth = authStore.use()
	const history = useHistory()
	const me = auth.me

	React.useEffect(() => {
		if (!notifications && me.group && me.group.toLowerCase().includes("recteam")) {
			getTerminalNotifications(setNotifications)
		}
	}, [])

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleLogOut = () => {
		authStore.signOut()
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setNotificationAnchorEl(event.currentTarget)
	}

	const handleNotificationClose = () => {
		setNotificationAnchorEl(null)
	}

	const handleButtonLinkClick = (notification_id, invoice_id) => {
		markNotificationRead(notification_id, () => { })
		history.push("/invoiceworkflow/" + invoice_id)
	}

	return (
		<Grid
			container
			style={{
				backgroundColor: "rgb(0,55,110)",
			}}>
			<Grid item xs={2}>
				<IconButton
					onClick={handleNotificationClick}
					aria-label="show 17 new notifications"
					color="inherit">
					<Badge badgeContent={notifications ? notifications.length : 0} color="error">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<Menu
					id="notificationMenu"
					anchorEl={notificationAnchorEl}
					open={Boolean(notificationAnchorEl)}
					onClose={handleNotificationClose}>
					{notifications &&
						notifications.map((noti) => {
							return (
								<MenuItem>
									<b>IEP</b>:{noti.iep.abbrv + ", "}&nbsp;
									<b>Invoice#</b>:
									<Button
										color="primary"
										onClick={() => {
											handleButtonLinkClick(noti.id_, noti.invoice_id)
										}}>
										{noti.invoice.invoice_num + ", "}
									</Button>
									<b>Record ID</b>:{noti.invoice_record_id}
								</MenuItem>
							)
						})}
				</Menu>
			</Grid>
			<Grid item xs={2}>
				<IconButton aria-label="account of current user" style={{ color: "rgb(255,255,255)" }}>
					<AccountCircle />
				</IconButton>
			</Grid>
			<Grid item xs={8} style={{ margin: "auto" }}>
				<div
					style={{
						color: "rgba(255, 255, 255, 1)",
						justifyItems: "center",
						padding: 5,
					}}>
					Hello, {me ? me.name : null}
				</div>
				<Grid container>
					<Grid item xs={6}>
						<Button
							aria-controls="fade-menu"
							aria-haspopup="true"
							style={{
								color: "rgba(110, 174, 222, 1)",
							}}
							onClick={handleLogOut}
							size="small">
							Log out
						</Button>
					</Grid>
					{me && me.group === "RecTeam" ? (
						<Grid item xs={1} style={{ padding: 10 }}>
							<Divider
								style={{
									backgroundColor: "rgba(110, 174, 222, 1)",
								}}
								orientation="vertical"
							/>
						</Grid>
					) : null}
					{me && me.group === "RecTeam" ? (
						<Grid item xs={4}>
							<Button
								aria-controls="fade-menu"
								aria-haspopup="true"
								style={{
									color: "rgba(110, 174, 222, 1)",
								}}
								onClick={handleClick}
								size="small">
								Admin
								<KeyboardArrowDownIcon />
							</Button>
							<StyledMenu
								id="customized-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}>
								<StyledMenuItem>
									<Link to="/usermanager">
										<ListItemText primary="Manage Users" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/customermanager">
										<ListItemText primary="Manage Customers" />
									</Link>
								</StyledMenuItem>{" "}
								<StyledMenuItem>
									<Link to="/iepmanager">
										<ListItemText primary="Manage IEPs" />
									</Link>
								</StyledMenuItem>{" "}
								<StyledMenuItem>
									<Link to="/terminalmanager">
										<ListItemText primary="Manage Terminals & GL Codes" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/sslmanager">
										<ListItemText primary="Manage SSLs" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/poolmanager">
										<ListItemText primary="Manage Pools" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/customerrulesmanager">
										<ListItemText primary="Manage Customer Rules" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/reasonmanager">
										<ListItemText primary="Manage Dispute Reasons" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/uploadfilemappingsmanager">
										<ListItemText primary="Manage Upload File Mappings" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/editemailtemplates">
										<ListItemText primary="Edit Email Templates" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/uploadmanager">
										<ListItemText primary="Upload Manager" />
									</Link>
								</StyledMenuItem>
								<StyledMenuItem>
									<Link to="/settings">
										<ListItemText primary="Settings" />
									</Link>
								</StyledMenuItem>
							</StyledMenu>
						</Grid>
					) : null}
				</Grid>
			</Grid>
			<div style={{ backgroundColor: "rgb(0,55,110)", width: "100%" }} />
		</Grid>
	)
}
